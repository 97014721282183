import React,  { useEffect } from "react";
import "../css/PublicHeader.css";
import { Container, Row, Col } from "reactstrap";
// import { basename } from "../config/buildSettings.js";

const PublicHeader = (props) => {

  return (
    <span className="header">
      <Container className="top-bar-outer-container">
        <Container className="top-bar-inner-container">
          <Row className="vermont-official-row align-items-center">
            <Col className="vermont-text-official">
              <a
                rel="noopener noreferrer"
                alt="Moon over Mountains Logo"
                href="http://vermont.gov/portal/"
                target="_blank"
                className="vermont-official"
              >
                An Official <strong>Vermont</strong> State Government Website
              </a>
            </Col>
            <Col className="vermont-logo-official">
              <a
                rel="noopener noreferrer"
                alt="State of Vermont Link"
                href="http://vermont.gov/portal/"
                target="_blank"
                className="vermont-logo"
                aria-label="vermont logo"
              >
                {" "}
              </a>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="site-name-outer-container">
        <Container className="site-name-inner-container">
          <Row className="site-name-row">
            <div className="site-name">State of Vermont - Division for Historic Preservation</div>
            <div
              rel="noopener noreferrer"
              alt="Site Subname Link"
              // href={basename}
              className="site-subname"
            >
              Vermont Roadside Historic Markers
            </div>
          </Row>
        </Container>
      </Container>
    </span>
  );
};

export default PublicHeader;
