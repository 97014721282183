import React from "react";
import PublicHeader from "./PublicHeader";
import "../css/App.css";

const App = () => {
  return (
    <div className="App">
      <PublicHeader className="header" />
      <div className="frame">
        <iframe
          className="frame"
          title="iframe"
          src="https://accd.maps.arcgis.com/apps/webappviewer/index.html?id=4998b29a06a141c088a6949879898cce"
          frameBorder="0"
          height={"100%"}
          width={"100%"}
        />
      </div>
    </div>
  );
};

export default App;
